<template>
  <div ref="thumbnail" class="flex flex-col gap-y-2">
    <div
      :style="posterStyle"
      class="h-full w-full relative thumbnail standard_thumbnail rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
    >
      <div class="w-full flex gap-x-1 items-center pt-2 pe-2 justify-end">
        <UiTagLive v-if="useIsLive(item)"></UiTagLive>
        <UiTagPlus v-if="item.showPlusLabel"></UiTagPlus>
      </div>
    </div>
    <h4
      class="text-title-small  text-white opacity-[0.87] font-bold line-clamp-2"
    >
      {{ label }}
    </h4>
  </div>
</template>

<script setup>
import { useIntersectionObserver } from "@vueuse/core";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const thumbnail = ref();
const isVisible = ref(false);
const posterStyle = ref();

posterStyle.value = useFallbackImage().style;
onMounted(() => {});

const imageToShow = computed(() => props.item.images?.horizontalimage);

const { stop } = useIntersectionObserver(
  thumbnail,
  ([{ isIntersecting }], observerElement) => {
    isVisible.value = isIntersecting;
  }
);

watch(isVisible, () => {
  if (isVisible.value && imageToShow.value) {
    posterStyle.value = { backgroundImage: `url('${imageToShow.value}')` };
    stop();
  }
});

const label = computed(() => useFindLabel(props.item));
</script>

<style lang="scss" scoped></style>
